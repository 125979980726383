<template>
  <div class="mod-sys-user-org">
    <amis-renderer :schema="schema" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import schema from './schemas/sys-user-org.json';

export default {
  name: 'SysUserOrg',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
};
</script>

<style lang="scss" scoped></style>
